import React, { useState } from "react";

import { Button } from "@mui/material";

function FloatingToolbar({ showing, setShowing, selected, reviewCandidates, requestHire }) {
  return (
    <div
      className={`d-flex flex-row floating-toolbar ${
        showing || selected.length > 0 ? `floating-toolbar-show` : ""
      }`}
    >
      <h5 className="my-auto" style={{ lineHeight: "20px" }}>
        {`${selected.length} selected candidate${
          selected.length > 1 ? "s" : ""
        }`}
      </h5>

      <Button
        className="btn-main gray outlined"
        style={{ marginLeft: "auto" }}
        onClick={() => reviewCandidates()}
      >
        Review {`candidate${selected.length > 1 ? "s" : ""}`}
      </Button>
      <Button
        className="btn-main"
        style={{ marginLeft: "16px" }}
        onClick={() => requestHire()}
      >
        Request hire
      </Button>
    </div>
  );
}

export default FloatingToolbar;
